import { Controller } from "stimulus";
import "selectize";

export default class extends Controller {
  static targets = ["tokenize"];
  
  connect() {
    const myModal = new bootstrap.Modal(
      document.getElementById("category-modal")
    );
    
    // Setup form handler once
    const form = $("#new-tag-form");
    let currentCallback = null;
    
    form.on("submit", function (e) {
      e.preventDefault();
      const input_value = $("#teaching_resource_new_tag").val();
      
      $.ajax({
        url: "/new_tags",
        method: "POST",
        data: JSON.stringify({ 
          new_tag: { name: input_value } 
        }),
        contentType: "application/json",
        success: function (response) {
          console.log("Tag created successfully:", response);
          if (currentCallback) {
            currentCallback({ value: response.name, text: response.name });
          }
          myModal.hide();
          $("#tokenize-selectized").trigger("focus");
          form.trigger("reset");
        },
        error: function(xhr, status, error) {
          console.error("Error creating tag:", error);
        }
      });
    });

    $(this.tokenizeTarget).selectize({
      create: function (input, callback) {
        currentCallback = callback;
        $("#teaching_resource_new_tag").val(input);
        myModal.show();
      }
    });
  }
}
